import type { ReactNode } from 'react'
import { useState, useImperativeHandle, forwardRef } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import * as Radix from '@radix-ui/react-dialog'
import cx from 'classnames'

export interface DialogProps extends Radix.DialogProps {
  trigger?: ReactNode // If provided, renders as a trigger to open the dialog. Alternatively use 'ref' to open programmatically
  title: string | ReactNode
  content: ReactNode
  className?: string
  isDisabled?: boolean
  position?: 'bottom' | 'central' | 'left'
  contentProps?: Radix.DialogContentProps
}

export interface DialogRef {
  openDialog: () => void
  closeDialog: () => void
}

const Dialog = forwardRef<DialogRef, DialogProps>(
  (
    { trigger, title, content, className, isDisabled, position = 'bottom', onOpenChange, contentProps, ...rest },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false)

    // Functions to open and close the dialog programmatically
    const openDialog = () => {
      setIsOpen(true)
      onOpenChange?.(true)
    }

    const closeDialog = () => {
      setIsOpen(false)
      onOpenChange?.(false)
    }

    // Expose open and close functions via ref
    useImperativeHandle(ref, () => ({
      openDialog,
      closeDialog
    }))

    const toggleOpen = (isOpen: boolean) => {
      onOpenChange?.(isOpen)
      setIsOpen(isOpen)
    }

    return (
      <Radix.Root open={isOpen} onOpenChange={toggleOpen} {...rest}>
        <Radix.Description className="hidden">{title}</Radix.Description>
        {trigger && (
          <Radix.Trigger asChild disabled={isDisabled}>
            {trigger}
          </Radix.Trigger>
        )}
        <Radix.Portal>
          <Radix.Overlay className="fixed inset-0 z-30 bg-black/60" />
          <Radix.Content
            className={cx(
              'dialog fixed z-40 flex flex-col overflow-y-hidden bg-white text-dark shadow-2xl dark:bg-baseGray dark:text-white',
              position === 'bottom' &&
                'radix-modal-long-animation bottom-0 left-0 right-0 max-h-almost-full w-screen rounded-t-2xl',
              position === 'left' && 'radix-modal-right-animation bottom-0 left-0 top-0 w-4/5 rounded-r-2xl',
              position === 'central' &&
                'inset-0 max-h-screen w-full max-w-screen sm:inset-auto sm:left-1/2 sm:top-1/2 sm:max-h-[calc(100vh-2rem)] sm:max-w-[632px] sm:-translate-x-1/2 sm:-translate-y-1/2 sm:rounded-2xl',
              className
            )}
            {...contentProps}>
            <div className="relative py-4 pl-6 pr-16 shadow-md">
              <Radix.Title>
                {typeof title === 'string' ? <span className="font-copy">{title}</span> : title}
              </Radix.Title>
              <Radix.Close asChild>
                <button
                  aria-label="Close"
                  onClick={closeDialog}
                  className="absolute right-4 top-4 rounded-full p-2 hover:bg-black/10 dark:hover:bg-white/20">
                  <XMarkIcon className="h-5" />
                </button>
              </Radix.Close>
            </div>
            <div className="flex-1 overflow-y-auto p-4 sm:p-6">{content}</div>
          </Radix.Content>
        </Radix.Portal>
      </Radix.Root>
    )
  }
)

Dialog.displayName = 'Dialog'

export default Dialog
